<template>
  <tr class="">
    <td class="px-4 py-2 text-emerald-600 font-medium">
      <router-link :to="{name: 'UploadEdit', params: {id: upload.id}}" v-if="upload.status === 'pending'">
        {{ upload.type === 'replace' ? upload.link : upload.title }}
      </router-link>
      <div v-if="upload.status === 'rejected'">{{ upload.type === 'replace' ? upload.link : upload.title }}</div>
      <div v-if="upload.status === 'approved'">
        <a
         :href="upload.result">{{ upload.type === 'replace' ? upload.link : upload.title }}</a>
      </div>
    </td>
    <td class="whitespace-nowrap px-4 py-2 text-emerald-600 font-medium">{{ timeago(upload.created_at) }}</td>
    <td class="px-4 py-2 text-emerald-600 font-medium">
      <span
          :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getUploadTypeClass(upload.type)">{{
          getUploadTypeText(upload.type)
        }}</span>
    </td>
    <td class="whitespace-nowrap px-4 py-2 text-emerald-600 font-medium">
      <span
          :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getUploadStatusClass(upload.status)">{{
          getUploadStatusText(upload.status)
        }}</span>
      <span v-if="upload.status === 'rejected' && upload.reason" class="mr-1"><i class="far fa-question-circle"
                                                                          :uk-tooltip="upload.reason"></i></span>
      <span class="flex items-center space-x-2 ml-2" v-if="upload.comments && upload.comments.paginatorInfo && upload.comments.paginatorInfo.total > 0">
        <ion-icon name="chatbubble-ellipses-outline" class="text-lg md hydrated" role="img"
                  aria-label="chatbubble ellipses outline"></ion-icon>
        <span> {{ upload.comments.paginatorInfo.total }} </span>
      </span>
    </td>
    <td class="whitespace-nowrap px-4 py-2 text-emerald-600 font-medium">
      <a v-if="upload.status === 'approved'"
         class="text-white inline-flex items-center h-5 px-3 m-2 text-sm transition-colors duration-150 bg-blue-700 focus:shadow-outline hover:bg-blue-600 rounded-lg"
         :href="upload.result">Kết quả</a>
      <router-link :to="{name: 'UploadDetail', params: {id: upload.id}}" v-if="upload.status === 'pending'"
                   class="inline-flex items-center h-5 px-3 m-2 text-sm transition-colors duration-150 bg-purple-100 focus:shadow-outline hover:bg-purple-200 rounded-lg">
        Xem trước
      </router-link>
      <router-link :to="{name: 'UploadEdit', params: {id: upload.id}}" v-if="upload.status === 'pending'"
                   class="inline-flex items-center h-5 px-3 m-2 text-sm transition-colors duration-150 bg-purple-100 focus:shadow-outline hover:bg-purple-200 rounded-lg">
        Sửa
      </router-link>
      <button v-on:click="deleteUpload(upload)" v-if="upload.status === 'pending'"
              class="inline-flex items-center h-5 px-3 m-2 text-sm transition-colors duration-150 bg-purple-100 focus:shadow-outline hover:bg-purple-200 rounded-lg">
        Xóa
      </button>
    </td>
  </tr>
</template>

<script>
import {
  getUploadStatusClass,
  getUploadStatusText,
  getUploadTypeClass,
  getUploadTypeText, timeago
} from "../../core/services/utils.service";

export default {
  name: "UploadItem",
  props: {
    upload: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  methods: {
    getUploadTypeText(x) {
      return getUploadTypeText(x);
    },
    getUploadTypeClass(x) {
      return getUploadTypeClass(x);
    },
    getUploadStatusText(x) {
      return getUploadStatusText(x);
    },
    getUploadStatusClass(x) {
      return getUploadStatusClass(x);
    },
    deleteUpload(upload) {
      this.$emit("deleteUpload", upload);
    },
    timeago(x) {
      return timeago(x);
    }
  }
}
</script>
