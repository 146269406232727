<template>
  <div>
    <div class="flex items-center justify-between relative">
      <div>
        <h2 class="text-xl font-semibold">Danh sách upload ({{ uploads.paginatorInfo.total }})</h2>
      </div>

      <div class="user-header1">
        <button aria-expanded="false"
                class="text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                type="button" data-dropdown-toggle="dropdown">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 mr-1">
            <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"></path>
          </svg>Upload
          <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
      </div>

      <div uk-drop="mode: click,hover;offset:5;delay-hide:200" class="header_dropdown profile_dropdown shadow-lg">
        <div class="p-2 font-semibold">Tác phẩm</div>
        <hr/>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'tan-nhac'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg class="text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
          </svg>
          Tân nhạc
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'dan-ca'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg class="text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
          </svg>
          Dân ca
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'khi-nhac'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
          </svg>
          Khí nhạc
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'thanh-vien-hat'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">

          <svg class="text-indigo-900" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
          </svg>
          Thành viên hát
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'tieng-tho'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">

         <svg class="text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="false"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path></svg>
          Tiếng thơ
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'thay-the-ban-thu'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">

         <svg class="text-orange-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="false"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path></svg>
          Thay thế bản thu
        </router-link>
        <hr/>
        <div class="p-2 font-semibold">Tư liệu</div>
        <hr/>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'tu-lieu-am-thanh'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-red-500">
            <path fill-rule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm3 2h6v4H7V5zm8 8v2h1v-2h-1zm-2-2H7v4h6v-4zm2 0h1V9h-1v2zm1-4V5h-1v2h1zM5 5v2H4V5h1zm0 4H4v2h1V9zm-1 4h1v2H4v-2z"
                  clip-rule="evenodd"></path>
          </svg>
          Âm thanh/video
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'tu-lieu-hinh-anh'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-purple-500">
            <path fill-rule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  clip-rule="evenodd"></path>
          </svg>
          Hình ảnh
        </router-link>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'tu-lieu-bai-viet'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Document Text</title><path d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path><path d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path></svg>
          Bài viết
        </router-link>
        <div class="p-2 font-semibold">Bản nhạc (sheet nhạc)</div>
        <hr/>
        <router-link :to="{name: 'UploadAdd', params: {prefix: 'sheet'}}"
                     class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Documents</title><path d="M336 264.13V436c0 24.3-19.05 44-42.95 44H107c-23.95 0-43-19.7-43-44V172a44.26 44.26 0 0144-44h94.12a24.55 24.55 0 0117.49 7.36l109.15 111a25.4 25.4 0 017.24 17.77z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path><path d="M200 128v108a28.34 28.34 0 0028 28h108" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path><path d="M176 128V76a44.26 44.26 0 0144-44h94a24.83 24.83 0 0117.61 7.36l109.15 111A25.09 25.09 0 01448 168v172c0 24.3-19.05 44-42.95 44H344" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path><path d="M312 32v108a28.34 28.34 0 0028 28h108" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path></svg>
          Bản nhạc
        </router-link>
      </div>
    </div>

    <div class="relative mt-6">
      <div class="card p-3">
        <table class="w-full">
          <thead>
          <tr>
            <th>Tiêu đề</th>
            <th>Ngày gửi</th>
            <th>Thể loại</th>
            <th>Trạng thái</th>
            <th>Thao tác</th>
          </tr>
          </thead>
          <tbody v-if="uploads.data.length">
          <UploadItem v-on:deleteUpload="deleteUpload" v-for="upload in uploads.data" :upload="upload"
                      :key="'upload' + upload.id"/>
          </tbody>
        </table>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="uploads.paginatorInfo.lastPage > 1"
              v-model="uploads.paginatorInfo.currentPage"
              :pages="uploads.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadUploads"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import UploadItem from "./UploadItem";

export default {
  name: 'UploadList',
  components: {
    VPagination,
    UploadItem
  },
  data() {
    return {
      currentUpload: null,
      uploads: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadUploads() {
      let query = `query($page: Int) {
        me {
          uploads(first: 10, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              id
              title
              created_at
              type
              status
              composers
              artists
              poets
              reason
              result
              link
              comments(first: 1) {
                paginatorInfo {
                  total
                }
              }
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {page: this.uploads.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.uploads) {
              this.uploads = data.data.me.uploads;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    deleteUpload(upload) {
      this.currentUpload = upload;

      if (confirm("Bạn có chắc muốn xóa bản thu/tư liệu này?")) {
        let query = `mutation($id: ID!) {
          deleteUpload(id: $id) {
            id
          }
        }`;

        ApiService.graphql(query, {id: this.currentUpload.id})
            .then(({data}) => {
              if (data.data && data.data.deleteUpload) {
                this.loadUploads()
              } else {
                alert(data.data.messages[0].message);
              }
            })
            .catch((response) => {
              alert(response.message);
              console.log(response);
            });
      }
    }
  },
  mounted() {
    this.loadUploads();
  }
}
</script>
